@font-face {
  font-family: 'AU Peto';
  font-display: swap;
  src: url('https://fonts.au.dk/fonts/au-peto.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

$small-screen-size: 640px;
$medium-screen-size: 1024px;
$large-screen-size: 1300px;
$width-border: 2px;
$color-foreground: #37a0cb;
$color-background: #002546;
$color-border: #878787;
$color-hover: #e2007a;
$color-inactive: #ccc;
$icon-biology: '\f0c3';
$icon-communication: '\f27a';
$icon-economics: '\f201';
$icon-physics: '\f5d3';
$icon-it: '\f1e6';
$icon-arts: '\f86d';
$icon-medicine: '\f21e';
$icon-media: '\f87a';
$icon-psychology: '\f5dc';
$icon-politics: '\f24e';
$icon-language: '\f0c0';
$icon-construction: '\f085';
$icon-philosophy: '\f66f';

@mixin pseudo-icon($position: absolute, $top: 50%, $right: auto, $left: 0, $width: 6rem, $height: 6rem, $padding-top: 1.75rem, $background: $color-background, $font-size: 2.5rem, $transform: translate(0, -50%)) {
  position: $position;
  top: $top;
  right: $right;
  left: $left;
  line-height: 1;
  padding-top: $padding-top;
  width: $width;
  height: $height;
  border-radius: 50%;
  text-align: center;
  background-color: $background;
  color: #fff;
  font-family: 'font-awesome';
  font-size: $font-size;
  font-weight: 300;
  transform: $transform;
}

@mixin centered-on-desktop() {
  width: 50%;
  margin: 0 auto 2rem auto;

  @media (max-width: $small-screen-size) {
    width: auto;
  }
}

@mixin box-shadow() {
  box-shadow: 0 0.2rem 0.6rem 0 rgba(0, 0, 0, .4);
}

@mixin processing-animation {
  position: relative;

  &::after {
    color: $color-background;
    position: absolute;
    left: calc(50% - 1em);
    top: calc(50% - 1em);
    display: inline-flex;
    align-items: center;
    justify-content: center;
    content: "D";
    font-family: "AU Peto";
    line-height: 1;
    transform-origin: center center;
    height: 2em;
    width: 2em;

    animation-name: animation-processing;
    animation-duration: 900ms;
    animation-delay: 300ms;
    animation-iteration-count: infinite;
    animation-direction: normal;
    animation-timing-function: ease-in-out;
  }

  @keyframes animation-processing {
    from {
      transform: rotateZ(-45deg);
      opacity: 1;
    }

    33% {
      opacity: .6;
    }

    to {
      transform: rotateZ(305deg);
      opacity: 1;
    }
  }
}

.bachelor {
  &__header {
    font-size: 3rem;

    @media (max-width: $small-screen-size) {
      font-size: 2.5rem;
    }
  }

  &__interests {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-bottom: 2rem;
    margin-top: 3rem;

    @media (max-width: $small-screen-size) {
      display: block;
    }

    &__subject {
      flex: 1 0 25%;
      display: flex;
      align-items: center;
      position: relative;
      line-height: 1;
      margin-bottom: 3rem;
      height: 5rem;

      &:nth-last-child(2) {
        margin-bottom: 0;
      }

      @media (max-width: $large-screen-size) {
        flex: 1 0 33%;
      }

      button {
        font: 2rem 'AUPassataRegular';
        cursor: pointer;
        text-align: left;
        padding-left: 7rem;
        margin-right: 1rem;

        &::before {
          @include pseudo-icon();
          @include box-shadow;
        }

        &:hover {
          color: $color-hover;

          &::before {
            background-color: $color-hover;
          }
        }
      }

      &--biology {
        button {
          &::before {
            content: $icon-biology;
          }
        }
      }

      &--business-communication {
        button {
          &::before {
            content: $icon-communication;
          }
        }
      }

      &--business-economics {
        button {
          &::before {
            content: $icon-economics;
          }
        }
      }

      &--physics {
        button {
          &::before {
            content: $icon-physics;
          }
        }
      }

      &--it {
        button {
          &::before {
            content: $icon-it;
          }
        }
      }

      &--arts {
        button {
          &::before {
            content: $icon-arts;
          }
        }
      }

      &--medicine {
        button {
          &::before {
            content: $icon-medicine;
          }
        }
      }

      &--media {
        button {
          &::before {
            content: $icon-media;
          }
        }
      }

      &--psychology {
        button {
          &::before {
            content: $icon-psychology;
          }
        }
      }

      &--politics {
        button {
          &::before {
            content: $icon-politics;
          }
        }
      }

      &--language {
        button {
          &::before {
            content: $icon-language;
          }
        }
      }

      &--construction {
        button {
          &::before {
            content: $icon-construction;
          }
        }
      }

      &--philosophy {
        button {
          &::before {
            content: $icon-philosophy;
          }
        }
      }
    }

    &__modal {
      @media (max-width: $small-screen-size) {
        position: absolute;
        top: 0;
        transform: translate(-50%, 0);
      }

      .close {
        top: -2rem;
        right: -2rem;
        background-color: $color-background !important;
        border-radius: 50%;
        color: #fff;
        width: 4rem;
        height: 4rem;
        overflow: hidden;
        @include box-shadow;

        @media (max-width: $medium-screen-size) {
          top: 2rem;
          right: 1rem;
        }

        &::before {
          content: '\f00d';
          display: block;
          padding-top: .5rem;
          line-height: 1;
          font-size: 3rem;
          font-family: 'font-awesome';
        }
      }
    }
  }

  &__education {
    position: relative;
    border-top: $width-border solid $color-border;
    padding: 1rem 10rem 1rem 0;
    font-family: 'AUPassataRegular';

    &::after {
      content: '\f061';
      @include pseudo-icon($right: 0, $left: auto, $width: 4rem, $height: 4rem, $padding-top: .8rem);
      @include box-shadow;

      @media (max-width: $small-screen-size) {
        @include pseudo-icon($right: 0, $left: auto, $width: 3rem, $height: 3rem, $padding-top: .8rem, $font-size: 1.5rem);
      }
    }

    @media (max-width: $small-screen-size) {
      font-size: 1rem;
      padding-right: 4rem;
    }

    &__note {
      background: $color-background;
      padding: .5rem 1rem;
      color: #fff;
      margin-bottom: .5rem;
      display: inline-block;
    }

    &__filter__container {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      position: relative;
      font-family: 'AUPassataRegular';
      padding: 2rem 0;

      @media (max-width: $small-screen-size) {
        display: block;
      }

      >span {
        display: inline-block;
        margin-right: 2rem;
        padding: .64rem 0;
        line-height: 1;

        &:nth-of-type(n+2) {
          margin-left: auto;
        }
      }
    }

    &__filter {
      position: relative;
      font-family: 'AUPassataBold';

      &:nth-of-type(n+5) {
        .bachelor__education__filter__button {
          margin-right: 0;
          padding-right: 0;
        }
      }

      &__button {
        font-family: inherit;
        color: inherit;
        cursor: pointer;
        text-align: left;
        padding: .64rem 1rem;
        margin-right: 1rem;
        border-radius: 16px;
        background-color: transparent;
        transition: background 150ms 50ms cubic-bezier(0.645, 0.045, 0.355, 1);

        &:not(.bachelor__education__filter__button--selected) {
          &:hover {
            color: $color-hover;
          }
        }

        &::after {
          display: inline-block;
          font-family: 'font-awesome';
          position: absolute;
          border-radius: 50%;
          content: '\f00d';
          background-color: #fff;
          color: $color-background;
          opacity: 0;
          transform: rotate(-100deg) scale(0.3);
          transition: transform 150ms cubic-bezier(0.55, 0.055, 0.675, 0.19), opacity 150ms cubic-bezier(0.55, 0.055, 0.675, 0.19);
          width: 24px;
          height: 24px;
          top: 2px;
          left: 2px;
          line-height: 24px;
          font-size: 17px;
          text-align: center;
        }

        &--selected {
          padding-left: 3rem;
          padding-right: 1.2rem;
          background-color: $color-background;
          color: #fff;
          transition: background 200ms cubic-bezier(0.645, 0.045, 0.355, 1);

          &::after {
            opacity: 1;
            transform: rotate(0deg) scale(1);
            transition: transform 150ms 50ms cubic-bezier(0.215, 0.61, 0.355, 1), opacity 150ms 50ms cubic-bezier(0.215, 0.61, 0.355, 1);
          }
        }
      }

      &__list {
        position: absolute;
        top: .64rem;
        left: 1rem;
        z-index: 20;
        font-size: 1.5rem;
        background-color: $color-background;
        width: 300px;
        padding-bottom: .5rem;
        visibility: hidden;
        opacity: 0;
        transform: rotate3d(0.5, 0, -0.05, 90deg) scale(0.85);
        transform-origin: 1.14rem 1rem;
        transition: all 170ms cubic-bezier(0.55, 0.055, 0.675, 0.19);

        &--right {
          right: 1rem;
          left: auto;

          @media (max-width: $small-screen-size) {
            right: auto;
            left: 1rem;
          }
        }

        &--visible {
          visibility: visible;
          opacity: 1;
          transform: rotate3d(0, 0, 0, 0) scale(1);
          transition: all 170ms cubic-bezier(0.215, 0.61, 0.355, 1);
        }

        &__select {
          display: block;
          margin: 1rem;
          font-size: 1.2rem;
          color: rgba(255, 255, 255, 0.75);
          position: relative;

          &::before {
            display: inline-block;
            position: absolute;
            content: '';
            left: 0;
            bottom: -6px;
            border-bottom: 2px solid rgba(255, 255, 255, 0.55);
            width: 20px;
          }
        }

        &__close {
          position: absolute;
          right: .65rem;
          top: .65rem;
          font-family: 'au_icons';
          font-size: 2rem;
          color: rgba(255, 255, 255, 0.75);
          cursor: pointer;
        }

        ul {
          margin: 0;

          button {
            margin-right: 0;
            padding: 1rem;
            display: block;
            width: 100%;
            font-family: inherit;
            color: #fff;
            cursor: pointer;
            text-align: left;

            &.item-selected {
              color: $color-background;
              background-color: #fff;
              border-radius: 0;
            }
          }
        }
      }
    }

    &__interest-container {

      >*:not(.bachelor__education__interest-container__wrapper) {
        margin-left: 9rem;
        margin-right: 9rem;
      }

      @media (max-width: $small-screen-size) {
        >*:not(.bachelor__education__interest-container__header) {
          margin-left: 0;
          margin-right: 0;
        }

        .bachelor__education__interest-container__header {
          margin-left: 3rem;
          margin-right: 0;
          font-size: 2rem;

          &::before {
            @include pseudo-icon($left: -5rem, $width: 4rem, $height: 4rem, $font-size: 2rem, $padding-top: 1rem);
          }
        }
      }

      &__header {
        margin-top: 0 !important;
        margin-bottom: 2rem;
        position: relative;
        font-size: 3rem;

        +.bachelor__education__container {
          padding-top: 2rem;
        }

        &::before {
          @include pseudo-icon($left: -8rem);
          @include box-shadow;
        }

        &--biology {
          &::before {
            content: $icon-biology;
          }
        }

        &--business-communication {
          &::before {
            content: $icon-communication;
          }
        }

        &--business-economics {
          &::before {
            content: $icon-economics;
          }
        }

        &--physics {
          &::before {
            content: $icon-physics;
          }
        }

        &--it {
          &::before {
            content: $icon-it;
          }
        }

        &--arts {
          &::before {
            content: $icon-arts;
          }
        }

        &--medicine {
          &::before {
            content: $icon-medicine;
          }
        }

        &--media {
          &::before {
            content: $icon-media;
          }
        }

        &--psychology {
          &::before {
            content: $icon-psychology;
          }
        }

        &--politics {
          &::before {
            content: $icon-politics;
          }
        }

        &--language {
          &::before {
            content: $icon-language;
          }
        }

        &--construction {
          &::before {
            content: $icon-construction;
          }
        }

        &--philosophy {
          &::before {
            content: $icon-philosophy;
          }
        }
      }
    }

    &__container {
      margin-bottom: 2rem;

      @media (max-width: $small-screen-size) {
        padding-left: 0;
        padding-right: 0;
      }

      >a {
        text-decoration: none;
        border: 0;


        &:last-child {
          .bachelor__education {
            border-bottom: $width-border solid $color-border;
          }
        }

        &:hover {
          h4 {
            color: $color-hover;
          }

          .bachelor__education::after {
            background-color: $color-hover;
          }
        }
      }
    }

    &__header {
      font-size: 2rem;
      margin-top: 0 !important;
      margin-bottom: 1rem !important;

      @media (max-width: $small-screen-size) {
        font-size: 1.5rem;
      }
    }

    &__children {
      margin-bottom: 2rem;

      li::before {
        font-family: "au_icons";
        content: "≥";
        font-size: 0.8rem;
        position: absolute;
        margin-left: -1.5rem;
        line-height: 2.5em;
        font-weight: bold;
        color: #003d73;
      }
    }

    &__info {
      display: flex;

      &__group {
        margin: 0 2rem 0 0;
        display: flex;

        dt {
          margin-right: 1rem;
        }
      }
    }

    &__link-to-all {
      display: block;
      @include box-shadow;


      &--centered {
        flex-basis: 33%;
        margin: 0;
      }
    }

    &__search {
      position: relative;
      font-family: 'AUPassataRegular';

      &--centered {
        flex-grow: 1;
        margin-left: 1rem;
      }

      h4 {
        font-size: 2rem;

        @media (max-width: $small-screen-size) {
          font-size: 1.5rem;
        }
      }

      input[type="text"] {
        height: 4.4rem;
      }

      &__item__note {
        background: $color-background;
        padding: .5rem 1rem;
        color: #fff;
        margin-left: .5rem;
        display: inline-block;
      }
    }

    &__alphabet {
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-start;
      margin-left: 0;

      &__letter {
        button {
          cursor: pointer;
          font-family: 'AUPassataBold';
          text-transform: uppercase;
          color: $color-background;
          padding: .5rem;

          &:hover {
            color: $color-hover;
          }
        }

        &--inactive {
          button {
            color: $color-inactive;
            cursor: default;
          }
        }

        &--selected {
          button {
            background-color: $color-background;
            color: #fff;

            &:hover {
              color: #fff;
            }
          }
        }
      }
    }

    &__no-results {
      display: flex;
      flex-direction: column;
      align-items: center;
      border-top: $width-border solid $color-border;
      border-bottom: $width-border solid $color-border;
      padding: 2rem 0;
      margin-bottom: 2rem;

      &__text {
        font-size: 2rem;
        font-family: 'AUPassataRegular';
        max-width: 35em;
        margin: 0 2rem;
        text-align: center;
        color: $color-border;
      }
    }
  }

  &__journey {
    &__header {
      text-align: center;
      font-size: 2rem;
      margin-bottom: 2rem;

      &::before {
        display: block;
        margin: 0 auto 1rem auto;
        @include pseudo-icon($position: initial, $transform: none, $padding-top: 1.45rem);
        @include box-shadow;
      }

      &--for-you {
        &::before {
          content: url('data:image/svg+xml;charset=utf8,<svg xmlns="http://www.w3.org/2000/svg" version="1.1" width="40" height="36"><path stroke="white" fill="none" stroke-width="1.64" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" transform="translate(-89,-126)" d="M126.5,137.08a10,10,0,0,0-9.74-9.73h-.25a10,10,0,0,0-8,4,10,10,0,0,0-8-4h-.25a10,10,0,0,0-9.74,9.73v.25a10,10,0,0,0,2.74,6.88l.38.38,13.32,13.31a2.21,2.21,0,0,0,3.15,0l13.32-13.31.38-.38a10,10,0,0,0,2.74-6.88Z" /></svg>');
        }
      }

      &--doubt {
        &::before {
          content: url('data:image/svg+xml;charset=utf8,<svg xmlns="http://www.w3.org/2000/svg" version="1.1" width="40" height="40"><g stroke="white" fill="none" stroke-width="1.64" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" transform="translate(-422,-205)"><path d="M457.59,234.3l-10.13-10.12a12.18,12.18,0,0,1-1.72,2.19,11.93,11.93,0,0,1-2.19,1.72l10.13,10.13a2.77,2.77,0,0,0,3.91-3.92Z" /><path d="M447.46,224.18a12,12,0,1,0-3.91,3.91" /><path d="M432.27,212a12,12,0,0,1,8.28-.65" /></g></svg>');
        }
      }

      &--admission {
        &::before {
          content: url('data:image/svg+xml;charset=utf8,<svg xmlns="http://www.w3.org/2000/svg" version="1.1" width="40" height="36"><g transform="translate(-766,-126)"><g fill="white"><path d="M772.12,132h-1.44v-1.1h2.63v6.43h-1.19Z" /><path d="M775.38,136.11h1.19v1.19h-1.19Z" /><path d="M774.57,145.38v1.1h-4.38c.45-.61.87-1.16,1.25-1.66l.47-.64.44-.57.34-.45a2.29,2.29,0,0,1,.18-.24,2.22,2.22,0,0,0,.3-.49,1.22,1.22,0,0,0,.12-.5.92.92,0,0,0-.23-.64.74.74,0,0,0-.59-.27.79.79,0,0,0-.61.24,1,1,0,0,0-.24.72h-1.19a2.06,2.06,0,0,1,.16-.84,2.1,2.1,0,0,1,.43-.65,2,2,0,0,1,.65-.42,1.94,1.94,0,0,1,.8-.16,2.1,2.1,0,0,1,.79.16,2.35,2.35,0,0,1,.64.44,2,2,0,0,1,.43.64,2.09,2.09,0,0,1,.16.78,2.2,2.2,0,0,1-.19.87,5.51,5.51,0,0,1-.55.91l-.22.29-.31.41-.37.48c-.14.16-.26.33-.38.49Z" /><path d="M775.38,145.28h1.19v1.2h-1.19Z" /><path d="M771.44,153.64a1.16,1.16,0,0,0,.24.75.85.85,0,0,0,.71.3.72.72,0,0,0,.33-.07.8.8,0,0,0,.28-.2.75.75,0,0,0,.18-.28,1,1,0,0,0,.07-.35,1.1,1.1,0,0,0-.07-.36,1,1,0,0,0-.2-.3,1,1,0,0,0-.29-.2.85.85,0,0,0-.39-.08v-1.07a.78.78,0,0,0,.6-.23.83.83,0,0,0,.22-.57.72.72,0,0,0-.06-.29.84.84,0,0,0-.15-.26.81.81,0,0,0-.24-.17.67.67,0,0,0-.28-.06.89.89,0,0,0-.31.06.77.77,0,0,0-.27.17.75.75,0,0,0-.18.3,1.11,1.11,0,0,0-.07.43h-1.2a2.41,2.41,0,0,1,.15-.87,1.92,1.92,0,0,1,.42-.65,1.83,1.83,0,0,1,.64-.4,2.18,2.18,0,0,1,.82-.15,1.9,1.9,0,0,1,.75.15,2.08,2.08,0,0,1,.61.4,2.11,2.11,0,0,1,.41.61,1.8,1.8,0,0,1,.15.73,1.58,1.58,0,0,1-.2.77,1.72,1.72,0,0,1-.49.54,1.55,1.55,0,0,1,.57.57,1.66,1.66,0,0,1,.25.93,1.85,1.85,0,0,1-.16.78,2.08,2.08,0,0,1-.44.64,2,2,0,0,1-1.45.58,2.11,2.11,0,0,1-1.58-.57,2.16,2.16,0,0,1-.57-1.58Z" /><path d="M775.38,154.46h1.19v1.2h-1.19Z" /></g><g stroke="white" fill="none" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.45"><line x1="779.67" y1="136.4" x2="801.84" y2="136.4" /><line x1="779.5" y1="145.58" x2="801.66" y2="145.58" /><line x1="779.85" y1="154.76" x2="802.01" y2="154.76" /></g></g></svg>');
        }
      }

      &--now-what {
        &::before {
          content: url('data:image/svg+xml;charset=utf8,<svg xmlns="http://www.w3.org/2000/svg" version="1.1" width="40" height="36"><g stroke="white" fill="none" stroke-width="1.64" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" transform="translate(-1008,-254)"><path d="M1018.53,264.79a8.88,8.88,0,1,1,17.75,0c0,6.95-9.7,7.23-9.7,15.1" /><circle cx="1026.54" cy="287.16" r="1.97" /></g></svg>');
        }
      }
    }

    &__wrapper {
      display: flex;
      justify-content: space-between;

      @media (max-width: $small-screen-size) {
        display: initial;
      }
    }

    &__text,
    &__links {
      flex-basis: 50%;
      font-size: 1.5rem;
      margin-bottom: 0;
    }

    &__text {
      font-family: 'AUPassataRegular';
    }

    &__links {
      font-family: 'AUPassataBold';

      li {
        margin-bottom: 0;

        a {
          margin-bottom: 1rem;
          padding: 1rem;
          display: block;
          text-align: left;
          @include box-shadow;
        }
      }

      @media (max-width: $small-screen-size) {
        margin-top: 2rem;

        li {
          padding-left: 0;
        }
      }
    }
  }

  &__link-and-search-container {
    flex: 1 0 75%;
    display: flex;
    align-self: stretch;
    align-items: center;
  }
}

.modal-view {
  display: none;
  position: fixed;
  z-index: 200;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 34, 64, .7);

  &--visible {
    display: block;
  }

  &--small {
    .modal-view__wrapper {
      max-width: 60rem;
    }
  }

  &__wrapper {
    position: relative;
    margin: 10rem auto 0 auto;
    max-width: 85rem;

    @media (max-width: $medium-screen-size) {
      margin-top: 5rem;
    }
  }
}

button.modal-view__close.button.button--icon.button--icon--hide-label {
  position: absolute;
  z-index: 201;
  top: -2rem;
  right: 2rem;
  display: inline-flex;
  padding: 0;
  overflow: hidden;
  white-space: nowrap;
  border-radius: 50%;
  width: 4rem;
  height: 4rem;
  align-items: center;
  font-size: 2rem;
  line-height: 1.5;
  font-weight: 400;
  border: none;
  text-decoration: none;
  text-align: center;
  @include box-shadow();
}


button.modal-view__close.button.button--icon.button--icon--hide-label::before {
  flex: 0 0 calc(100% + 1px);
  margin: 0;
  text-align: center;
  margin-right: 0;
  content: attr(data-icon);
  display: inline-block;
  font-family: 'font-awesome';
  line-height: 1;
}

.modal-view__body {
  height: auto;
  max-height: 85vh;
  background-color: white;
  overflow: auto;
  padding: 3rem;
  @include box-shadow();

  @media (max-width: $medium-screen-size) {
    max-height: 80vh;
  }
}

.u-no-scroll {
  height: 100vh;
  overflow: hidden;
}

.page--blur {
  -o-filter: blur(.2rem);
  -ms-filter: blur(.2rem);
  -moz-filter: blur(.2rem);
  -webkit-filter: blur(.2rem);
  filter: blur(.2rem);
}

.processing-state {
  @include processing-animation;
  min-height: calc(8rem);

  &::after {
    color: $color-background;
    top: 0;
    font-size: 4rem;
  }

  @keyframes animation-delay-spinner {
    from {
      opacity: 0;
    }

    99% {
      opacity: 0;
    }

    to {
      opacity: 1;
    }
  }
}

.bachelor-journey-desktop {

  .cls-10,
  .cls-12,
  .cls-13,
  .cls-15,
  .cls-16,
  .cls-17,
  .cls-2,
  .cls-3,
  .cls-9 {
    fill: none;
  }

  .cls-10,
  .cls-12,
  .cls-13,
  .cls-15,
  .cls-16,
  .cls-2,
  .cls-3,
  .cls-9 {
    stroke: #231f20;
  }

  .cls-12,
  .cls-13,
  .cls-15,
  .cls-16,
  .cls-2 {
    stroke-miterlimit: 10;
  }

  .cls-2,
  .cls-3 {
    stroke-width: 1.69px;
  }

  .cls-10,
  .cls-12,
  .cls-17,
  .cls-3,
  .cls-9 {
    stroke-linecap: round;
  }

  .cls-10,
  .cls-17,
  .cls-3,
  .cls-9 {
    stroke-linejoin: round;
  }

  .cls-4 {
    opacity: 0.25;
    mix-blend-mode: multiply;
  }

  .cls-5 {
    fill: #d4effc;
  }

  .cls-6 {
    font-size: 15px;
    font-family: 'AUPassataRegular';
  }

  .cls-14,
  .cls-6 {
    fill: #231f20;
  }

  .cls-7 {
    letter-spacing: -0.04em;
  }

  .cls-8 {
    letter-spacing: -0.01em;
  }

  .cls-12,
  .cls-13,
  .cls-9 {
    stroke-width: 1.64px;
  }

  .cls-10 {
    stroke-width: 1.47px;
  }

  .cls-11 {
    letter-spacing: -0.02em;
  }

  .cls-15 {
    stroke-width: 1.45px;
  }

  .cls-16 {
    stroke-width: 1.77px;
  }

  .cls-17 {
    stroke: #939598;
    stroke-width: 1.42px;
  }
}

.bachelor-journey-mobile {

  .cls-10,
  .cls-11,
  .cls-12,
  .cls-13,
  .cls-14,
  .cls-15,
  .cls-2,
  .cls-3,
  .cls-9 {
    fill: none;
  }

  .cls-14,
  .cls-15,
  .cls-2,
  .cls-3,
  .cls-9 {
    stroke: #231f20;
  }

  .cls-11,
  .cls-12,
  .cls-13,
  .cls-2,
  .cls-9 {
    stroke-miterlimit: 10;
  }

  .cls-2,
  .cls-3 {
    stroke-width: 1.69px;
  }

  .cls-10,
  .cls-12,
  .cls-14,
  .cls-15,
  .cls-3 {
    stroke-linecap: round;
  }

  .cls-10,
  .cls-14,
  .cls-15,
  .cls-3 {
    stroke-linejoin: round;
  }

  .cls-4 {
    opacity: 0.25;
    mix-blend-mode: multiply;
  }

  .cls-5 {
    fill: #d4effc;
  }

  .cls-6 {
    font-size: 21px;
    font-family: 'AUPassataRegular';
  }

  .cls-6,
  .cls-8 {
    fill: #231f20;
  }

  .cls-7 {
    letter-spacing: -0.02em;
  }

  .cls-9 {
    stroke-width: 1.45px;
  }

  .cls-10,
  .cls-11 {
    stroke: #939598;
    stroke-width: 1.42px;
  }

  .cls-12,
  .cls-13 {
    stroke: #000;
  }

  .cls-12,
  .cls-13,
  .cls-14 {
    stroke-width: 1.64px;
  }

  .cls-15 {
    stroke-width: 1.47px;
  }

  .cls-16 {
    letter-spacing: -0.04em;
  }

  .cls-17 {
    letter-spacing: -0.01em;
  }
}

.bachelor-journey-desktop,
.bachelor-journey-mobile {
  .journey {
    &-hover {
      cursor: pointer;

      &-box {
        fill: $color-background;
      }

      &-text {
        fill: #fff;
      }

      &-icon {
        stroke: #fff;
      }
    }
  }
}

#bachelor-journey {
  pointer-events: none;

  .for-you,
  .doubt,
  .admission,
  .now-what {
    pointer-events: auto;
  }
}